import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LinkClickHandlersProvider, name, PreviewTooltipCallbackSymbol } from './symbols'
import { ClickHandlerRegistrar } from './clickHandlerRegistrar'
import { LifeCycle, IPageDidMountHandler } from '@wix/thunderbolt-symbols'
import { ClickHandlersProvider } from './linkClickHandlersProvider'
import type { IPreviewTooltipCallback } from './types'

export const page: ContainerModuleLoader = (bind) => {
	bind(LinkClickHandlersProvider).to(ClickHandlersProvider)
	bind<IPageDidMountHandler>(LifeCycle.PageDidMountHandler).to(ClickHandlerRegistrar)
}

export { name, PreviewTooltipCallbackSymbol }
export { IPreviewTooltipCallback }
